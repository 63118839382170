import React from 'react';
import { Link } from 'gatsby';
import Container from '../components/layout/default/container';
import { statesOptions } from '../utils/helpers';
import SectionTitle from '../components/layout/admin/section-title';
import EmptyState from '../components/empty/empty-state-notification-request';
import SEO from '../components/layout/seo';


function CentersGridList({ centers, stateLabel, stateAbbreviation }) {
  const hasCenters = !!(centers && centers.length);
  return (
    <div className="my-24">
      <SectionTitle title={`Bowling Centers in ${stateLabel}`} />
      {!hasCenters && (
        <EmptyState
          value={stateAbbreviation}
          valueKey="state"
          headline={`We'll let you know when centers are ready in ${stateLabel}`}
          details="Enter your email address to get notified as soon as centers and leagues are added in your area."
          successHeadline="You've been signed up!"
          successDetails={`We'll let you know when centers are ready in ${stateLabel}.`}
        />
      )}
      {hasCenters && (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
          {centers.map((center) => (
            <div
              key={center.id}
              className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <div className="flex-1 min-w-0">
                <Link to={`/${center.slug}`} className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">{center.name}</p>
                  <p className="text-sm text-gray-500 truncate">{center.city}</p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default function Centers({ pageContext }) {
  const { centers } = pageContext;
  const statesAsOptions = statesOptions(true);

  return (
    <Container>
      <SEO title="Bowling Centers" />
      <div className="mx-auto max-w-6xl">
        {statesAsOptions.map(({ value, label }) => <CentersGridList key={label} stateAbbreviation={value} stateLabel={label} centers={centers[value]} />)}
      </div>
    </Container>
  );
}
